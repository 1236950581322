import Vue from "vue";
import VueRouter from "vue-router";
import index from "../views/index.vue";
// import las from './views/las'
// import test from './views/test'
// import core from './views/core'
// import core2 from './views/core2'
// import algorithm from './views/algorithm'
// import algorithm1 from './views/algorithm1'
// import algorithm2 from './views/algorithm2'
// import algorithm3 from './views/algorithm3'
// import page1 from './views/page1'
// import page2 from './views/page2'
// import page3 from './views/page3'
// import page4 from './views/page4'
// import page5 from './views/page5'
// import page6 from './views/page6'

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: index,
  },
  {
    path: "/page1",
    name: "机器学习智能分类决策系统",
    component: () =>
      import("../views/page1.vue"),
  },
  {
    path: "/page2",
    name: "模型训练",
    component: () =>
      import("../views/page2.vue"),
  },
  {
    path: "/page3",
    name: "模型评分",
    component: () =>
      import("../views/page3.vue"),
  },
  {
    path: "/page4",
    name: "直井智能选层优化设计",
    component: () =>
      import("../views/page4.vue"),
  },
  {
    path: "/page5",
    name: "智能聚类算法",
    component: () =>
      import("../views/page5.vue"),
  },
  {
    path: "/page6",
    name: "水平井段簇优化设计",
    component: () =>
      import("../views/page6.vue"),
  },
  {
    path: "/page7",
    name: "机器学习自动化回归系统模型评估",
    component: () =>
      import("../views/page7.vue"),
  },
  {
    path: "/page8",
    name: "机器学习模型应用",
    component: () =>
      import("../views/page8.vue"),
  },
  {
    path: "/page9",
    name: "异常值自动处理",
    component: () =>
      import("../views/page9.vue"),
  },
  {
    path: "/page10",
    name: "协方差选择",
    component: () =>
      import("../views/page10.vue"),
  },
  {
    path: "/page11",
    name: "特征自动选择方法",
    component: () =>
      import("../views/page11.vue"),
  },
  {
    path: "/page12",
    name: "特征降维处理",
    component: () =>
      import("../views/page12.vue"),
  },
  {
    path: "/page13",
    name: "交叉协方差选择",
    component: () =>
      import("../views/page13.vue"),
  },
  {
    path: "/page14",
    name: "微地震与压裂段数据链接",
    component: () =>
      import("../views/page14.vue"),
  },
  {
    path: "/page15",
    name: "示踪剂与压裂段数据链接",
    component: () =>
      import("../views/page15.vue"),
  },
  {
    path: "/page16",
    name: "钻测录与压裂段数据链接",
    component: () =>
      import("../views/page16.vue"),
  },
  {
    path: "/page17",
    name: "信号智能滤波处理",
    component: () =>
      import("../views/page17.vue"),
  },
  {
    path: "/page18",
    name: "微观孔隙结构参数提取",
    component: () =>
      import("../views/page18.vue"),
  },
  {
    path: "/page19",
    name: "井旁道地震道数据提取",
    component: () =>
      import("../views/page19.vue"),
  },
  {
    path: "/page20",
    name: "多地震属性体合并",
    component: () =>
      import("../views/page20.vue"),
  },
  {
    path: "/page21",
    name: "智能聚类",
    component: () =>
      import("../views/page21.vue"),
  },
  {
    path: "/page22",
    name: "合成地震记录",
    component: () =>
      import("../views/page22.vue"),
  },
  {
    path: "/page23",
    name: "图像扩维处理",
    component: () =>
      import("../views/page23.vue"),
  },
  {
    path: "/page24",
    name: "图像翻转增益",
    component: () =>
      import("../views/page24.vue"),
  },
  {
    path: "/page25",
    name: "图像尺寸处理",
    component: () =>
      import("../views/page25.vue"),
  },
  {
    path: "/page26",
    name: "深度学习图像识别智能决策系统",
    component: () =>
      import("../views/page26.vue"),
  },
  {
    path: "/page27",
    name: "深度学习图像识别智能决策系统",
    component: () =>
      import("../views/page27.vue"),
  },
  {
    path: "/page28",
    name: "VPS井震数据链接",
    component: () =>
      import("../views/page28.vue"),
  },
  {
    path: "/page29",
    name: "深度学习语义分割智能决策系统",
    component: () =>
      import("../views/page29.vue"),
  },
  {
    path: "/page30",
    name: "图像滤波处理",
    component: () =>
      import("../views/page30.vue"),
  },
  {
    path: "/page31",
    name: "图像滤波处理",
    component: () =>
      import("../views/page31.vue"),
  },
  {
    path: "/page32",
    name: "page32",
    component: () =>
      import("../views/page32.vue"),
  },
  {
    path: "/page33",
    name: "page33",
    component: () =>
      import("../views/page33.vue"),
  },
  {
    path: "/page34",
    name: "page34",
    component: () =>
      import("../views/page34.vue"),
  },
  {
    path: "/page35",
    name: "page35",
    component: () =>
      import("../views/page35.vue"),
  },
  {
    path: "/page36",
    name: "page36",
    component: () =>
      import("../views/page36.vue"),
  },
  {
    path: "/page37",
    name: "page37",
    component: () =>
      import("../views/page37.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
