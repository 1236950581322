import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    action_type:['目标参数', '特征参数', '其他', '忽略',
    '深度索引', '层位索引', '段索引', '簇索引'
    , '顶深索引', '底深索引', '井名索引', 'X索引', 'Y索引', 'Z索引', '顶X索引', '顶Y索引', '顶Z索引', '底X索引',
    , '底Y索引', '底Z索引', '时间索引', '时间段索引', '起始时间索引', , '结束时间索引', 'TWT','补心海拔'
    ,'完钻井深','井斜角','方位角','垂深','顶垂深','底垂深','inline','xline','断层名称','断层线名称','I','J','K'],
      value_type:['浮点型数值', '指数浮点型数值', '整型数值', '字符型数值'],
  },
  getters: {},
  mutations: {},
  actions: {},
  modules: {},
});
