<template>
  <v-app>
 
    <v-container>
  <v-tabs>
    <v-tab to="/">基础模板</v-tab>
    <!-- <v-tab to="/core">实验岩心测试数据去重处理</v-tab> -->
  
    <v-tab to="/page1">层位后处理</v-tab>
    <v-tab to="/page2">地震数据加载</v-tab>
    <v-tab to="/page3">层位数据加载</v-tab>
    <v-tab to="/page4">断层数据加载</v-tab>
    <v-tab to="/page5">层位数据处理</v-tab>
    <v-tab to="/page6">断层数据处理</v-tab>
    <v-tab to="/page7">层位识别数据集构建</v-tab>
    <v-tab to="/page8">层位模型训练</v-tab>
    <v-tab to="/page9">层位模型评估</v-tab>
    <v-tab to="/page10">层位模型应用</v-tab>
    <v-tab to="/page11">断层识别数据集构建</v-tab>
    <v-tab to="/page12">断层模型训练</v-tab>
    <v-tab to="/page13">断层模型评估</v-tab>
    <v-tab to="/page14">断层后处理</v-tab>
    <v-tab to="/page15">人工合成地震数据</v-tab>

    <v-tab to="/page16">断层模型应用</v-tab>
    <!-- <v-tab to="/page17">地震属性提取</v-tab> -->
    <v-tab to="/page18">小波变换时频特征提取</v-tab>
    <v-tab to="/page19">井旁道地震道数据提取</v-tab>
    <v-tab to="/page20">多地震属性体合并</v-tab>
    <v-tab to="/page21">地震属性提取</v-tab>
    <v-tab to="/page22">合成地震记录</v-tab>
    <v-tab to="/page23">图像扩维处理</v-tab>
    <v-tab to="/page24">图像翻转增益</v-tab>
    <v-tab to="/page25">图像尺寸处理</v-tab>
    <v-tab to="/page26">深度学习图像识别智能决策系统</v-tab>
    <v-tab to="/page27">深度学习结构化数据回归智能决策系统</v-tab>
    <v-tab to="/page28">VPS井震数据链接</v-tab>
    <v-tab to="/page29">深度学习语义分割智能决策系统</v-tab>
    <v-tab to="/page30">图像滤波处理</v-tab>
    <v-tab to="/page31">json文件解编</v-tab>
    <v-tab to="/page32">深度学习结构化数据分类智能决策</v-tab>
    <v-tab to="/page33">基于合成地震记录数据井震数据大表构建</v-tab>
    <v-tab to="/page34">断层成果数据标注地震数据大表</v-tab>
    <v-tab to="/page35">多井单深度坐标计算</v-tab>
    <v-tab to="/page36">多井井斜坐标计算</v-tab>
    <v-tab to="/page37">多井顶底坐标计算</v-tab>
  </v-tabs>

    </v-container>

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    //
  }),
};
</script>
